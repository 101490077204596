#Calendar td {
  text-align: center;
}

.page-btn {
  padding: 6px;
  min-width: 40px;
  min-height: 40px;
  border: none;
  border-radius: 12px;
  font-weight: 800;
  text-align: center;
}

.page-btn img{
  text-align: center;
}

.calendar-step {
  font-family: 'Alegreya';
  padding: 12px;
  min-width: 40px;
  min-height: 40px;
  border: none;
  border-radius: 12px;
  font-weight: 600;
  font-size: 0.8em;
  text-align: center;
}

.selected {
  border-radius: 6px;
  background-color: var(--newPostText);
  /* border: 2px solid var(--newPostText) !important; */
}

.selected h3 {
  color: white;
}

.selected-slot {
  border-radius: 6px;
  color: white;
  background-color: var(--newPostText);
}

.occupied {
  background-color: rgb(101, 45, 45);
}

.calendar-slot {
  /* font-family: 'Alegreya'; */
  min-width: 90px;
  min-height: 90px;
  border: none;
  cursor: pointer;
  font-size: 0.66em;
}

.calendar-slot:hover {
  transform: scale(1.05);
}

.calendar-days th {
  /* font-family: 'Alegreya'; */
  min-width: 120px;
  max-width: 120px;
  max-height: 90px;
  padding-bottom: 2em;
}

/* Small devices (landscape tablets, phones, 768px and down) */
@media (max-width: 480px) {
  .calendar-days th {
    /* font-family: 'Alegreya'; */
    min-width: 40px;
    max-width: 40px;
    max-height: 40px;
  }

  .calendar-slot {
    min-width: 40px;
    min-height: 40px;
  }
}

/* Small devices (landscape tablets, phones, 768px and down) */
@media only screen and (min-width: 481px) and (max-width: 767px) {
  .calendar-days th {
    /* font-family: 'Alegreya'; */
    min-width: 60px;
    max-width: 60px;
    max-height: 60px;
  }

  .calendar-slot {
    min-width: 60px;
    min-height: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 900px) {
  .calendar-days th {
    /* font-family: 'Alegreya'; */
    min-width: 90px;
    max-width: 90px;
    max-height: 90px;
  }

  .calendar-slot {
    min-width: 90px;
    min-height: 90px;
  }
}

/* Medium devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 901px) and (max-width: 1199px) {

}

/* Large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) and (max-width: 2559px) {

}

/* Extra large devices (large laptops and desktops, 2560px and up) */
@media only screen and (min-width: 2560px) {

}