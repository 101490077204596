.notification-modal {

  position: absolute;
  width: 400px;
  max-width: 500px;
  height: 224px;
  max-height: 400px;
  /* margin-left: -180px; */
  right: 20px;
  border: 1px solid #d7d7d7;
  z-index: 100;
  background-color: var(--navBG);
  color: var(--navText);
  box-shadow: 0px 6px 14px var(--navShadow);
  overflow: scroll;
  overflow-x: hidden;
}

.notification {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0;
  border-bottom: 1px solid lightgrey;
  cursor: pointer;
}

.notification:hover {
  background-color: rgb(219, 219, 219);
}

.notice-date {

  color: rgb(165, 124, 42);
}

.notice-alert {
  position: absolute;
  right: 0px;
  top: 2px;
  color: rgb(255, 140, 0);
  background-color: rgb(255, 140, 0);
  border-radius: 12px;
  width: 12px;
  height: 12px;
  overflow: hidden;
}


/* Extra Small devices 576px and down */
@media (max-width: 576px) {
  .notification-modal {
    position: fixed;
    width: 100vw;
    height: auto;
    max-height: 65vh;
    left: 0;
    margin-left: 0;
    border: 1px solid grey;
    z-index: 100;
    background-color: var(--navBG);
    color: var(--navText);
    box-shadow: 0px 6px 14px var(--navShadow);
    overflow: scroll;
    overflow-x: hidden;
  }
}

/* Small devices (landscape tablets, phones, 768px and down) */
@media (min-width: 577px) and (max-width: 768px) {
  .notification-modal {

    position: absolute;
    width: 80vw;
    left: 0;
    margin-left: 0;
    border: 1px solid grey;
    z-index: 100;
    background-color: var(--navBG);
    color: var(--navText);
    box-shadow: 0px 6px 14px var(--navShadow);
    overflow: scroll;
    overflow-x: hidden;
  }
}

/* Medium devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 769px) and (max-width: 1199px) {
  .notification-modal {

    position: absolute;
    width: 305px;
    margin-left: -180px;
    border: 1px solid grey;
    z-index: 100;
    background-color: var(--navBG);
    color: var(--navText);
    box-shadow: 0px 6px 14px var(--navShadow);
    overflow: scroll;
    overflow-x: hidden;
  }
}

/* Large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) and (max-width: 2559px) {

}

/* Extra large devices (large laptops and desktops, 2560px and up) */
@media only screen and (min-width: 2560px) {

}