#PropertyPage {

  background-color: #fafafa;
  min-height: 90vh;
}

#PropertyThumb {
  background-color: #fafafa;
  /* min-height: 90vh; */
}

.unit-block {
  display: flex;
  justify-content: center;
  margin-bottom: 1em;
}

.unit-block button {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--navButtonGold) !important;
  background-color: white !important;
  border-width: 2px !important;
}

.unit-block button:nth-child(1) {
  margin-right: 1em;
}

.unit-block .unit-block-selected {
  color: rgb(121, 161, 65) !important;
  border-color: rgb(121, 161, 65) !important;
}

.property-menu {
  font-family: 'Alegreya';
  justify-content: center;

  font-weight: 500;
  background-color: var(--navButtonGold);
  border: none;
  border-radius: 6px;
}

.property-menu button {
  font-family: inherit;

  font-weight: inherit;
  background-color: inherit;
  border: none;
  border-radius: 2px;
  padding: 6px 12px;
}

.property-menu-selected {
  color: #3a3a3a;
  background-color: #e0bf80 !important;
  /* border: 1px solid rgb(255, 249, 234) !important; */
}

.property-menu button:nth-child(2) {
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.main-property-div {
  display: inline-block;
  position: relative;
  width: 60%;
}
.main-property-div-r {
  display: inline-block;
  position: relative;
  overflow: hidden;
  width: 40%;
  height: 100%;
  vertical-align: top;
  padding-left: 5%;
}

.fixed-scroller {
  position: fixed;
  top: 9vw;
}

.features-list {
  list-style: disc;
}

.features-list li {
  padding: 2px;
}

.related-units {
  position: relative;
  /* display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; */
  border: 1px solid var(--newPostText);
  border-radius: 6px;
  /* max-width: 1000px; */
}

.related-units > button {
  color: var(--newPostText);
  text-align: center;
  min-width: 170px;
  width: calc((100% / 5) - 3px);

  font-weight: 600;
  padding: 0.2em;
  border: 0;
  border-right: 1px solid var(--newPostText);
  font: inherit;
  background-color: transparent;
}

.related-units > button:nth-child(3)::after { content: "1 Bedroom";}
.related-units > button:nth-child(4)::after { content: "2 Bedroom";}
.related-units > button:nth-child(5)::after { content: "3 Bedroom";}

.monthly-fee {
  border: 1px solid;
  border-radius: 8px 0 0 0;
}
.footage {
  border: 1px solid;
  border-radius: 0 8px 0 0;
}
.bedrooms::after { content: "bedrooms";}
.bathrooms::after { content: "bathrooms";}

.related-units > button:last-child {
  border-right: 0;
}

.related-units > .selected {
  color: white;
  background-color: var(--newPostText);
}

.related-listings {
  position: relative;
  border: 1px solid rgb(207, 207, 207);
  padding: 12px;
  /* max-width: 1000px; */
  border-radius: 6px;
}

.maximize-btn {
  display: block;
  position: absolute;

  font-weight: bold;
  padding: 2px 8px;
  min-width: 32px;
  top: 5px;
  right: 10px;
  z-index: 5;
  color: var(--newPostText);
  background-color: transparent;
  border: 1px solid;
  border-radius: 12px;
  border-color: var(--newPostText);
  border: none
}

.maximize-btn:hover {
  transform: scale(1.105);
}

.unit-images {
  display: block;
  height: 100px;
  width: 120px;
  position: absolute;
  right: 8vw;
  padding: 0.25em;
  border: 1px solid grey;
  border-radius: 6px;
}
.unit-images button {
  width: 100%;
  height: 100%;
  border: none;
  padding: 2px;
  overflow: hidden;
}

.unit-images button > img {
  height: 100%;
  width: 100%;
}

.property-images {
  display: flex;
  position: relative;
  list-style: none;
  overflow: hidden;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  -ms-overflow-style: none;
  scrollbar-width: none;
  /* overscroll-behavior: contain; */ 
  scroll-padding: 0 16px;
  padding: 0px;
  /* max-height: 760px; */
  background-color: #f5f5f5;
  margin: 0;
}

.property-images li {
  flex-shrink: 0;
}

.property-images button {
  width: 100%;
  height: 100%;
  border: none;
  padding: 2px;
  overflow: hidden;
}

.property-images button > img {
  height: 100%;
  width: 100%;
}

.full-img-p {
  width: 100vw;
}

.large-img-p {
  width: 50%;
}

.small-img-p {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 25%;
}

.photo-counter {
  display: inline-block;
  position: absolute;

  font-weight: bold;
  left: 50%;
  bottom: -12px;
  padding: 6px 12px;
  background-color: white;
  /* border: 1px solid rgb(205, 205, 205); */
  border-radius: 12px;
  box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.104);
  color: var(--newPostText);
  pointer-events: none;
  transform: translateX(-50%);
}

.left-pane {
  display: block;
  position: absolute;
  left: 0;
  width: 34px;
  height: 100%;
  border: 0;
  background-color: #1b1d2309;
  z-index: 12;
  pointer-events: all;
}

.right-pane {
  display: block;
  position: absolute;
  right: 0;
  width: 34px;
  height: 100%;
  border: 0;
  background-color: #1b1d2309;
  z-index: 12;
  pointer-events: all;
  cursor: pointer;
}

.arrow-overlay {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: transparent;
  pointer-events: none;
  cursor: pointer;
}

.arrow {
  position: relative;
  border: solid rgb(255, 255, 255);
  border-width: 0 8px 8px 0;
  box-shadow: 4px 4px 6px rgba(217, 217, 217, 0.637);
  display: block;
  padding: 8px;
  /* filter: invert(118%) sepia(28%) saturate(3234%) hue-rotate(128deg) brightness(204%) contrast(101%); */
}

.arrow-overlay .arrow {
  position: absolute;
  top: 50%;
  z-index: 2;
  /* filter: invert(118%) sepia(28%) saturate(3234%) hue-rotate(128deg) brightness(204%) contrast(101%); */
}

.arrow-black {
  border: solid rgb(0, 0, 0);
  border-width: 0 6px 6px 0;
}

.arrow-overlay > .left-pane .arrow:first-child {
  left: 10px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}
.arrow-overlay > .right-pane .arrow:last-child {
  right: 10px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}


.arrow-up-base {
  margin-left: 10px;
  border-width: 0 4px 4px 0 !important;
  box-shadow: none;
  padding: 4px !important;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}
.arrow-down-base {
  margin-left: 10px;
  border-width: 0 4px 4px 0 !important;
  box-shadow: none;
  padding: 4px !important;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.arrow-up {
  top: 0px;
  margin-left: 16px;
  border-width: 0 4px 4px 0 !important;
  box-shadow: none;
  padding: 12px !important;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}
.arrow-down {
  top: 0px;
  margin-left: 16px;
  border-width: 0 4px 4px 0 !important;
  box-shadow: none;
  padding: 12px !important;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.img-upload-btn {
  display: block;
  position: relative;
  width: 180px;
  height: 160px;
  border: none !important;
  /* border-radius: 0 !important; */
  overflow: hidden;
}

.img-upload-btn img {
  width: 100%;
  height: 100%;
}

.img-upload-btn-2 {
  display: block;
  position: relative;
  width: 120px;
  height: 120px;
  border: none !important;
  /* border-radius: 0 !important; */
  overflow: hidden;
}

.img-upload-btn-2 img {
  width: 100%;
  height: 100%;
}

.move-in-box {
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  background-color: rgba(220,243,251,.2);
  border: 1px dashed #0576a7;
  border-radius: 12px;
}
.move-in-box > p {
  padding: 2px;
  margin: 2px;
}

.contact-box {
  margin-bottom: 1em;
}

.contact-owner {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 1em;
  width: 100%;
  max-width: 31vw;
  border: 1px solid rgb(207, 207, 207);
  border-radius: 12px;
}

.contact-owner-still {
  display: flex;
  flex-direction: column;
  /* text-align: center; */
  padding: 1em;
  /* width: 100%; */
  /* max-width: 31vw; */
  border: 1px solid rgb(207, 207, 207);
  border-radius: 12px;
}
.contact-inside-still {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  align-items: center;
}
.contact-inside-still > div:nth-child(2) {
  margin-left: 16px;
  margin-right: 16px;
}

.contact-btn {
  display: inline-block;
  position: relative;
  color: #ffffff;
  background-color: var(--newPostText);
  padding: 1.25em;

  /* min-width: 140px; */
  border: none;
  border-radius: 12px;
  text-decoration: none !important;
  line-height: 0;
  text-align: center;
  /* margin-left: auto; margin-right: auto; */
  cursor: pointer;
}

.contact-btn:hover {
  transform: scale(1.005);
  /* color: var(--navText) !important; */
  background-color: var(--links);
}

.contact-btn2 {
  font-family: 'Source Sans Pro';
  color: #ffffff;
  background-color: var(--newPostText);
  width: 90%;
  padding: 12px 52px;

  font-weight: 600;
  border: none;
  border-radius: 6px;
  text-decoration: none !important;
  /* max-width: 400px; */
  text-align: center;
  /* margin-left: auto; margin-right: auto; */
  cursor: pointer;
}

.contact-btn2 > * {
  color: white;
  font-weight: 600;
}

.contact-btn3 {
  font-family: 'Source Sans Pro';
  color:  var(--newPostText);
  background-color: var(--mainBG);
  border: 1px solid var(--newPostText);
  width: 90%;
  padding: 12px 52px;

  font-weight: 600;
  border-radius: 6px;
  text-decoration: none !important;
  /* max-width: 400px; */
  text-align: center;
  /* margin-left: auto; margin-right: auto; */
  cursor: pointer;
}

.contact-btn3 > * {
  color:  var(--newPostText);
  font-weight: 600;
}

.contact-btn2-still {
  font-family: 'Source Sans Pro';
  color: #ffffff;
  background-color: var(--newPostText);
  padding: 8px 42px;

  font-weight: 600;
  border: none;
  border-radius: 6px;
  text-decoration: none !important;
  /* max-width: 400px; */
  text-align: center;
  /* margin-left: auto; margin-right: auto; */
  cursor: pointer;
}

.contact-btn2-still > * {
  color: white;
  font-weight: 600;
}

.contact-btn3-still {
  font-family: 'Source Sans Pro';
  color:  var(--newPostText);
  background-color: var(--mainBG);
  border: 1px solid var(--newPostText);
  padding: 8px 42px;

  font-weight: 600;
  border-radius: 6px;
  text-decoration: none !important;
  /* max-width: 400px; */
  text-align: center;
  /* margin-left: auto; margin-right: auto; */
  cursor: pointer;
}

.contact-btn3-still > * {
  color:  var(--newPostText);
  font-weight: 600;
}

.border-bottom {
  border-bottom: 1px solid rgb(207, 207, 207);
}

.contact-icon  {
  color: white;
  width: 1.3em;
  height: auto;
}

.available-btn {
  display: block;
  position: relative;
  color: var(--newPostText);
  background-color: transparent;
  padding: 1.25em;

  width: auto;
  border: 1px solid var(--newPostText);
  border-radius: 12px;
  text-decoration: none !important;
  line-height: 0;
  text-align: center;
  /* margin-left: auto; margin-right: auto; */
  cursor: pointer;
  max-width: 260px;
}

.review-btn {
  display: block;
  position: relative;
  color: #3a3a3a;
  background-color: var(--navButtonGold);
  padding: 18px 12px;
  font-family: 'Alegreya';

  font-weight: 700;
  width: auto;
  /* border: 1px solid rgb(215, 129, 15); */
  border: none;
  border-radius: 12px;
  text-decoration: none !important;
  line-height: 0;
  text-align: center;
  /* margin-left: auto; margin-right: auto; */
  cursor: pointer;
}

.property-table {
  max-width: 100%;
  border-radius: 12px;
  position: relative;
  padding: 22px 8px;
  border: 1px solid rgb(207, 207, 207);
  /* background-color: rgb(243, 243, 243); */
}

.property-table > div > div {
  padding: 10px 0 10px 0;
}

.property-tableheader {
  text-transform: capitalize;
  color: white;
  background-color: var(--newPostText);
  padding: 6px;
  border-radius: 12px 12px 0 0;
}
.property-data {
  border: 1px solid rgb(224, 234, 243);
  border-radius: 0 0 6px 6px;
  padding: 6px 10px;
}

.review-box {
  border: 1px solid rgb(207, 207, 207);
  min-width: 48%;
  min-height: 240px;
  margin-right: 0.5em;
}

.property-header-btn {
  display: inline-block;
  position: relative;
  /* color: #ffffff; */
  background-color: rgb(234, 235, 236);
  /* padding: 1em; */
  font-family: 'Barlow Condensed', cursive;

  font-weight: 400;
  width: auto;
  border: none;
  border-radius: 12px;
  text-decoration: none !important;
  line-height: 0;
  text-align: center;
  box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.104);
  cursor: pointer;
}

.bg-danger-subtle-shadow {
  box-shadow: 0px 2px 4px 2px rgba(238, 15, 15, 0.104);
}

.bg-info-subtle-shadow {
  box-shadow: 0px 2px 4px 2px rgba(3, 217, 255, 0.104);
}

.property-header-btn:hover {
  transform: scale(1.005);
  /* color: var(--navText) !important; */
  /* background-color: var(--links); */
}

.property-thumb-btn {
  color: #ffffff;
  background-color: rgb(255 255 255 / 80%);
  padding: 6px;

  border: 1px solid rgb(179, 179, 179);
  border-radius: 8px 8px 0 8px;
  text-decoration: none !important;
  line-height: 0;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}

.property-thumb-btn:hover {
  transform: scale(1.005);
  /* color: var(--navText) !important; */
  background-color: rgba(72, 171, 217, 0.692);
}
.cog-spin {
  animation: logo-spin infinite 30s linear;
}

.cog-spin:hover {
  animation: logo-spin infinite 10s linear;
}

.danger-btn {
  border-color: rgb(153, 23, 23) !important;
  background-color: rgb(153, 23, 23) !important;
}

.danger-btn:hover {
  background-color: rgb(202, 57, 57) !important;
}

.selected-cover-btn {
  border-color: rgb(81, 153, 23) !important;
  background-color: rgb(81, 153, 23) !important;
}

.selected-cover-btn:hover {
  background-color: rgb(142, 202, 57) !important;
}

.grey-btn {
  border-color: rgb(81, 81, 81) !important;
  background-color: rgb(81, 81, 81) !important;
}

.grey-btn:hover {
  background-color: rgb(36, 36, 36) !important;
}

.property-owner-tag {
  font-family: 'Alegreya';
  position: absolute;
  width: 50%;
  bottom: 0%;
  /* left: -1.5%; */
  left: 25%;

  color: white;
  text-align: center;
  border: 2px solid;
  border-top: 2px solid;
  border-bottom: 2px solid;
  border-radius: 8px 8px 0 0;
  /* border-radius: 0 0 12px 12px; */
  border-color: rgb(211 167 86);
  background-color: rgb(211 167 86);
  pointer-events: none;
  text-transform: uppercase;
}

.property-renter-tag {
  font-family: 'Alegreya';
  position: absolute;
  width: 50%;
  bottom: 0%;
  /* left: -1.5%; */
  left: 25%;

  color: white;
  text-align: center;
  border: 2px solid;
  border-top: 2px solid;
  border-bottom: 2px solid;
  border-radius: 8px 8px 0 0;
  /* border-radius: 0 0 12px 12px; */
  border-color: rgb(22, 56, 66);
  background-color: rgb(22, 56, 66);
  pointer-events: none;
  text-transform: uppercase;
}

.verified-btn {
  max-width: 126px;
  /* border: 1px solid #abdeab; */
  border-radius: 22px;
  background-color: #e7f9e738;
  box-shadow: rgb(91 207 91 / 26%) 0px 2px 4px 2px;
  cursor: help;
  margin-top: -8px;
}

.verified-btn img {
  height: 26px;
  margin-right: 0.5rem;
}

.verified-btn-thumb {
  /* max-width: 126px; */
  /* border: 1px solid #abdeab; */
  padding: 6px;
  border-radius: 22px;
  background-color: #ffffffcd;
  box-shadow: rgba(233, 240, 233, 0.631) 0px 2px 4px 2px;
  cursor: help;
}

.filter-green {
  filter: invert(50%) sepia(59%) saturate(3234%) hue-rotate(161deg) brightness(94%) contrast(101%);
}
.filter-verified {
  filter: invert(50%) sepia(59%) saturate(3083%) hue-rotate(126deg) brightness(94%) contrast(86%);
}

.filter-star {
  filter: invert(65%) sepia(59%) saturate(3234%) hue-rotate(10deg) brightness(107%) contrast(103%);
}

.filter-white {
  filter: invert(65%) sepia(60%) saturate(3166%) hue-rotate(192deg) brightness(173%) contrast(179%);
}

.filter-red {
  filter: invert(57%) sepia(67%) saturate(3150%) hue-rotate(-40deg) brightness(149%) contrast(159%);
}

.heart {
  width: auto;
  height: 38px;
}

.heart:hover {
  filter: invert(57%) sepia(67%) saturate(3150%) hue-rotate(-40deg) brightness(149%) contrast(159%);
}

.register-text > * { color: white; }


.register-form {
  /* border: 1px solid whitesmoke; */
  box-shadow: 1px 8px 14px 3px whitesmoke;
}

.form-sub-text {

  margin-left: 4px;
}

/* form h1, form h2, form h3, form h4, form h5, form h6 {
  font-family: 'Barlow Semi Condensed', cursive;
} */

#NewForm .edit-form {
  display: flex;
  flex-direction: column;
  max-width: 720px;
  margin: auto;
  padding: 16px;
  background-color: var(--mainBG);
  border-radius: 6px;
  border: 1px solid #dfe0e4;
}
#NewForm .edit-form input {
  padding: 8px;
  border: 1px solid var(--navButton);
  border-radius: 12px;
  outline-color: #3a425c;

  margin-bottom: 16px;
}
#NewForm .edit-form textarea {
  padding: 8px;
  border: 1px solid var(--navButton);
  border-radius: 12px;

  outline-color: #3a425c;
  margin-bottom: 1em;
  min-height: 120px;
}
#NewForm .edit-form select {
  padding: 8px;
  border: 1px solid var(--navButton);
  border-radius: 12px;

  outline-color: #3a425c;
  margin-bottom: 12px;
}
#NewForm .edit-form select:required {
  border: 1px solid rgb(248, 151, 55);
}

#NewForm .edit-form input[type="checkbox"] {
  margin-right: auto;
}

#NewForm .edit-form input:required {
  border: 1px solid rgb(248, 151, 55);
}

#NewForm .edit-form button {

  font-family: 'Barlow Semi Condensed', sans-serif;
  color: #3a3a3a;
  padding: 8px;
  border: 1px solid var(--navButtonGold);
  background-color: var(--navButtonGold);
  border-radius: 12px;
  /* min-width: 112px; */
  max-width: 360px;
  cursor: pointer;
}

#EditForm {
  min-height: 90vh;
  background-color: var(--mainGrey);
}

#EditForm .edit-form {
  display: flex;
  flex-direction: column;
  max-width: 720px;
  margin: auto;
  padding: 16px;
  background-color: var(--mainBG);
  border-radius: 6px;
  border: 1px solid #dfe0e4;
  box-shadow: 2px 12px 14px 8px rgb(229, 225, 225);
}
#EditForm .edit-form input {
  /* padding: 8px; */
  /* border: 1px solid var(--navButton); */
  /* border-radius: 12px; */
  /* outline-color: #3a425c; */

  margin-bottom: 16px;
}
#EditForm .edit-form textarea {
  padding: 8px;
  /* border: 1px solid var(--navButton); */
  border-radius: 12px;

  outline-color: #3a425c;
  margin-bottom: 1em;
  min-height: 120px;
}
#EditForm .edit-form select {
  padding: 8px;
  /* border: 1px solid var(--navButton); */
  border-radius: 12px;

  outline-color: #3a425c;
  margin-bottom: 12px;
}
#EditForm .edit-form select:required {
  border: 1px solid rgb(248, 151, 55);
}

#EditForm .edit-form input[type="checkbox"] {
  margin-right: auto;
}

#EditForm .edit-form input:required {
  border: 1px solid rgb(248, 151, 55);
}

#EditForm .edit-form button {

  font-family: 'Barlow Semi Condensed', sans-serif;
  color: #3a3a3a;
  /* padding: 8px; */
  border: 1px solid var(--navButtonGold);
  background-color: var(--navButtonGold);
  /* border-radius: 12px; */
  /* min-width: 140px; */
  /* max-width: 360px; */
  cursor: pointer;
}

/* Tenant Info */
#TenantList {
  padding: 16px;
  background-color: var(--mainBG);
  border-radius: 6px;
  border: 1px solid #dfe0e4;
  box-shadow: 2px 12px 14px 8px rgb(229, 225, 225);
  margin-bottom: 1em;
}

#TenantList .tenant-box {

}

#TenantList .tenant-box button {

  font-family: 'Barlow Semi Condensed', sans-serif;
  color: #3a3a3a;
  padding: 8px;
  border: 1px solid var(--navButtonGold);
  background-color: var(--navButtonGold);
  border-radius: 12px;
  /* min-width: 140px; */
  max-width: 180px;
  cursor: pointer;
}


#TenantList button {

  font-family: 'Barlow Semi Condensed', sans-serif;
  color: #3a3a3a;
  padding: 8px;
  border: 1px solid var(--navButtonGold);
  background-color: var(--navButtonGold);
  border-radius: 12px;
  /* min-width: 140px; */
  max-width: 360px;
  cursor: pointer;
}

#TenantList .tenant-pic {
  width: 100%;
  height: 100%;
}

.tenant-flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.tenant-info {

}

.tenant-info .tenant-pic {
  width: 160px !important;
  height: auto !important;
  margin: auto;
  margin-bottom: 1em;
}

.tenant-star {
  width: 35px !important;
}

.tenant-rate {

}

.payment-form {
  font-family: 'Source Sans Pro', 'Barlow Semi Condensed', sans-serif;

  display: flex;
  flex-direction: column;
  max-width: 540px;
  margin: auto;
  padding: 16px;
  background-color: #ffffffea;
  box-shadow: 2px 12px 14px 8px #ffffffea;
}
.payment-form input {
  padding: 8px;
  border: 1px solid var(--navButton);
  border-radius: 6px;
  outline-color: #3a425c;

  margin-top: 4px;
}
.payment-form select {
  padding: 8px;
  border: 1px solid var(--navButton);
  border-radius: 12px;

  outline-color: #3a425c;
  margin-bottom: 6px;
}
.payment-form select:required {
  border: 1px solid rgb(248, 151, 55);
}

.payment-form input[type="checkbox"] {
  margin-right: auto;
}

.payment-form input:required {
  border: 1px solid rgb(248, 151, 55);
}

.payment-form textarea {
  padding: 8px;
  border: 1px solid var(--navButton);
  border-radius: 12px;

  outline-color: #3a425c;
  /* margin-bottom: 1em; */
}

.payment-form button {

  /* font-family: 'Barlow Semi Condensed', sans-serif; */
  font-family: 'Alegreya', 'Barlow Semi Condensed', sans-serif;
  color: #3a3a3a;
  padding: 8px 22px;
  border: 1px solid var(--navButtonGold);
  background-color: var(--navButtonGold);
  border-radius: 6px;
  cursor: pointer;
  margin-top: 32px;
}

/* Small devices (landscape tablets, phones, 424px and down) */
@media (max-width: 424px) {
  .contact-owner-still {
    text-align: center;
  }

  .property-menu button {
    width: 100%;
  }

  .property-menu button:nth-child(2) {
    margin-left: 0;
    margin-right: 0;
  }

  .unit-images {
    height: 100px;
    width: 120px;
    right: 1vw;
  }
  .maximize-btn {
    display: none;
  }
  .maximize-btn-sm {
    display: block;
    position: absolute;
    padding: 5px;
    top: 5px;
    right: 10px;
    z-index: 5;
    background-color: transparent;
    border: 1px solid;
    border-radius: 12px;
    border-color: var(--newPostText);
    border: none;
  }
  .maximize-btn-lg {
    display: block;
    position: absolute;
    padding: 5px;
    top: auto;
    bottom: 10px;
    right: 10px;
    z-index: 5;
    background-color: transparent;
    border: 1px solid;
    border-radius: 12px;
    border-color: var(--newPostText);
    border: none;
  }
}

@media (max-width: 620px) {
  .contact-owner-still {
    text-align: center;
  }
  .contact-inside-still {
    text-align: center;
    flex-direction: column;
  }
  .contact-inside-still > div:nth-child(2) {
    margin-top: 12px;
    margin-bottom: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid lightgrey;
  }
}
/* Small devices (laptops/desktops, 424px and up to 770px) */
@media (min-width: 425px) and (max-width: 768px) {
  .unit-images {
    height: 100px;
    width: 120px;
    right: 86px;
  }
}

/* Medium devices (laptops/desktops, 770px and up to 1024px) */
@media (max-width: 768px) {
  .property-images button {
    width: 100%;
  }

  .bathrooms {
    border: 1px solid;
    border-radius: 0 8px 0 0;
  }
  .main-property-div {
    display: block;
    width: auto;
  }

  .property-table {

  }
  
  .arrow {
    border-width: 0 6px 6px 0;
    padding: 6px;
  }
  .arrow-up {
    top: 12px;
  }
  .arrow-down {
    top: 12px;
  }
  .related-listings {
    padding: 8px;

  }

  .related-units > button {
    min-width: 60px;
    width: calc((100% / 5) - 3px);

  }

  .related-units > button:nth-child(3)::after { content: "1 BR";}
  .related-units > button:nth-child(4)::after { content: "2 BR";}
  .related-units > button:nth-child(5)::after { content: "3 BR";}

  .bedrooms::after { content: "Beds";}
  .bathrooms::after { content: "Baths";}

  .property-tableheader {

  }
  .property-data {

  }

  .property-images {
    /* display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    align-content: flex-start; */
    width: 100%;
    /* max-height: 560px; */
    height: 30vmax;
    /* background-color: ghostwhite; */
  }

  .property-images button {
    width: 100%;
    height: 100%;
    background-color: var(--navBG);
    border: none;
    padding: 0 2px;
    overflow: hidden;
  }

  .contact-owner {
    width: auto;
    margin: auto;
  }

  .contact-btn {
    width: auto;
  }

  .review-box {
    min-width: 100%;
    margin-right: 0;
  }

  #EditForm .edit-form {
    max-width: 520px;
  }

  .tenant-flex {
    justify-content: center;
  }
}

/* Medium devices (laptops/desktops, 770px and up to 1024px) */
@media (min-width: 769px) and (max-width: 1024px) {
  .property-images button {
    width: 100%;
  }
  .main-property-div {
    display: block;
    width: auto;
  }

  .related-units > button {
    min-width: 66px;
    width: calc((100% / 5) - 3px);
  }

  .property-images {
    /* display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    align-content: flex-start; */
    height: 30vmax;
  }
  .contact-owner {
    width: auto;
  }
}

/* Medium devices (laptops/desktops, 1025px and up) */
@media (min-width: 1025px) and (max-width: 1199px) {
  .main-property-div {
    display: block;
    width: auto;
    /* width: 55%; */
  }

  .related-units > button {
    min-width: 66px;
    width: calc((100% / 5) - 3px);
  }

  .property-images {
    /* display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    align-content: flex-start; */
    height: 30vmax;
  }
  .contact-owner {
    width: auto;
  }
}

/* Large devices (large laptops and desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 2559px) {
  .related-units > button {
    min-width: auto;
    width: calc((100% / 5) - 3px);
  }

  .property-images {
    /* display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    align-content: flex-start; */
    height: 30vmax;
  }

  #EditForm { padding-top: 2em; }
}

/* Extra large devices (large laptops and desktops, 2560px and up) */
@media (min-width: 2560px) {
  .fixed-scroller {
    top: 16vw;
  }
  .main-property-div-r {
    padding-left: 15%;
  }
  .contact-owner {
    width: 100%;
    max-width: 22vw;
  }
  .property-images {
    /* display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    align-content: flex-start; */
    height: 66vh;
  }
  .unit-images {
    height: 200px;
    width: 320px;
    right: 8vw;
    padding: 0.25em;
  }
  .contact-box {
    display: block;
    position: absolute;
    right: 0; top: 0;
    margin-bottom: 0;
  }

  #EditForm { padding-top: 2em; }
}