.mail-modal-content {
  position: relative;
  top: 50%;
  font-size: 0.9em;
  color: var(--navText);
  text-shadow: none;
  background-color: #fff;
  padding: 12px;
  /* min-height: 420px; */
  max-width: 620px;
  /* max-height: 720px; */
  border-radius: 8px;
  margin: auto;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 1001;
  transform: translateY(-50%);
}

.mail-modal-form {
  font-size: 1.3em;
  border-radius: 6px;
}
.mail-modal-form input {
  padding: 8px;
  border: 1px solid var(--navButton);
  border-radius: 6px;
  outline-color: #3a425c;
  font-size: 0.54em;
  min-width: 240px;
  margin-bottom: 16px;
}
.mail-modal-form textarea {
  padding: 8px;
  border: 1px solid var(--navButton);
  border-radius: 6px;
  font-size: 0.54em;
  outline-color: #3a425c;
  margin-bottom: 1em;
}
.mail-modal-form select {
  padding: 8px;
  border: 1px solid var(--navButton);
  border-radius: 12px;
  font-size: 0.54em;
  outline-color: #3a425c;
  margin-bottom: 12px;
}
.mail-modal-form select:required {
  border: 1px solid rgb(248, 151, 55);
}

.mail-modal-form input[type="checkbox"] {
  margin-right: auto;
}

.mail-modal-form input[type="tel"] {
  margin-bottom: auto;
}

.mail-modal-form input:required {
  border: 1px solid rgb(248, 151, 55);
}

.mail-modal-form button {
  font-size: 0.7em;
  font-family: 'Barlow Semi Condensed', sans-serif;
  color: #ffffff;
  padding: 8px;
  border: 1px solid var(--navButtonGold);
  background-color: var(--navButtonGold);
  border-radius: 6px;
  min-width: 140px;
  max-width: 360px;
  cursor: pointer;
}