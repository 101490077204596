:root {
  /* Default Threme */
  --links: #43b6da;
  --mainGrey: #eeeeef;
  --mainBG: #f9f9f9;
  --navBG: #f7f7f7;
  /* --navBG: #eaf6f7; */
  --navButton: #000000;
  --acctButton:rgb(236, 236, 236);
  /* --navButton: #97c0c4; */
  /* --navButtonGold: #e9b85c; */
  --navButtonGold: #f5d9a3;
  --buttonBlue: rgb(38, 204, 249);
  --buttonBlueBorder: rgb(85, 183, 192);
  --navShadow: #eeeeeee6;
  /* --navShadow: #d2edeee6; */
  --navText: #272e2f;
  --headerText: #4b4723;
  --forumHeaderBG: #e9d8b4;
  --subforumheaderBG: #e9e4b4;
  --postText: #4b4723;
  --postBG: #fff8e6;
  --postHover: #fdf3db;
  --pinHover: #f7edd5;
  --authorColor: #6b2f2f;
  /* --newPostText: #6fd3da; */
  --newPostText: rgb(38, 155, 184);
  --newPostBorder: #ccc292;
  --newPostBG: #fdf4dd;
  --footerBG: #f1ebb3;
  /* --mainBG: #e7de7a;
  --navBG: #97cce1; */

    /* Dark Threme */
    /* --links: #d37426;
    --mainBG: #222;
    --navBG: #2b2b2b;
    --navText: #e0cec4;
    --headerText: #3a3938;
    --forumHeaderBG: #e29555;
    --subforumheaderBG: #80532c;
    --postText: #e4af85;
    --postBG: #2b2b2b;
    --postHover: #3b3b3b;
    --pinHover: #4b4b4b;
    --authorColor: #8b4220;
    --newPostText: #d1b77f;
    --newPostBorder: #965e0f;
    --newPostBG: #312820;
    --footerBG: #2b2b2b; */

    /* Hacker Threme */
    /* --links: #43da9b;
    --mainBG: #f0fff7;
    --navBG: #b3f1b8;
    --navText: #4b4723;
    --headerText: #4b4723;
    --forumHeaderBG: #b4e9d7;
    --subforumheaderBG: #d7e9b4;
    --postText: #4b4723;
    --postBG: #f4ffe6;
    --postHover: #fdf3db;
    --pinHover: #f7edd5;
    --authorColor: #6b2f2f;
    --newPostText: #4c9ca1;
    --newPostBorder: #92ccc4;
    --newPostBG: #ddfdf5;
    --footerBG: #b3f1e4; */
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

/* Set default transition durations */
* {
  -webkit-transition-duration: 0.25s;
  -moz-transition-duration: 0.25s;
  transition-duration: 0.25s;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Alegreya', 'Asap', 'Poppins', 'Barlow', 'Barlow Condensed', cursive !important;
  font-weight: 600;
  margin: 0;
  text-transform: capitalize;
  color: #2e2e2e;
}
.main-header {
  font-family: 'Barlow', 'Barlow Condensed', 'Poppins', cursive !important;
}
.logo { font-family: 'Barlow Sremi Condensed', sans-serif; font-weight: 900; }
.header { font-family: 'Barlow Condensed', sans-serif; font-weight: 800; }
a { color: var(--links); text-decoration: none;}
a:hover { text-decoration: underline; }

button:hover { transform: scale(1.025); cursor: pointer; }

.screen-height { min-height: 90vh; }

.not-button {
  display: block !important;
  background: none !important;
	color: inherit !important;
	border: none;
	padding: 0;
	font: inherit !important;
	cursor: pointer !important;
	outline: inherit !important;
  text-align: inherit !important;
}

.bare-btn {
  display: inherit;
  appearance: none;
  padding: inherit;
  font: inherit;
  text-align: inherit;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.bare-btn:hover { transform: scale(1.005); }

.nav-btn {
  position: relative;
  margin-left: 0.3rem;

  color: var(--navText);
  background-color: transparent;
  padding: 8px 16px;
  border-radius: 20px;
  text-decoration: none !important;
  /* line-height: 0; */
  cursor: pointer;
}
.nav-btn:hover {
  transform: scale(1.005);
  color: #ffffff !important;
  background-color: var(--navButton);
}

.nav-notify-btn {
  display: inline-block;
  position: relative;
  background-color: transparent;
  border: none;
  border-radius: 48px;
  padding: 8px;
  text-decoration: none !important;
  line-height: 0;
  cursor: pointer;
}
.nav-notify-btn:hover {
  transform: scale(1.005);
  color: #ffffff !important;
  background-color: var(--acctButton);
}

.nav-acct-btn {
  display: inline-block;
  position: relative;
  margin-left: 0.3rem;
  color: var(--navText);
  background-color: transparent;
  padding: 8px;
  border: none;
  border-radius: 48px;
  text-decoration: none !important;
  line-height: 0;
  cursor: pointer;
}
.nav-acct-btn:hover {
  transform: scale(1.005);
  color: #ffffff !important;
  background-color: var(--acctButton);
}

.selected-nav-acct-btn {
  background-color: var(--acctButton);
}
.nav-search-form {
  display: flex;
  position: relative;
  align-items: center;
  height: 34px;
  flex-wrap: nowrap;
  justify-content: center;
}

.nav-search-bar {
  font-family: 'Source Sans Pro';
  font-weight: 350;
  display: block;
  padding: 2px 12px;
  height: 100%;
  width: 280px;

  color: rgb(121, 121, 121);
  background-color: #fffffff3;
  border: 1px solid var(--navButtonGold);
  border-radius: 4px;
  cursor: pointer;
}
.search-select-opt {
  font-weight: normal;
}

.nav-button-search {
  font-family: 'Source Sans Pro';
  display: inline-block;
  position: relative;
  line-height: 0;
  height: 100%;
  margin-left: 0.1rem;

  /* color: white; */
  color: #ffffff;
  background-color: #e2c58c;
  padding: 8px 18px;
  border-radius: 6px;
  border-style: solid;
  border-width: 0;
  text-decoration: none !important;
  cursor: pointer;
}
.nav-button-search:hover {
  transform: scale(1.005);
  color: #ffffff !important;
  border: 0 0 0;
  background-color: var(--navButton);
}

.search-btn {
  display: block;
  padding: 2px 6px;
  width: 280px;

  color: rgb(179, 179, 179);
  background-color: #ffffff;
  border: 1px solid rgb(185, 185, 185);
  text-align: center;
}

.selected-nav-btn {
  color: #ffffff !important;
  background-color: var(--navButton);
}

.bg-navbar-wave {
  position: absolute;
  left: 0; top: 0;
  width: 100%; height: 100%;
  /* background-image: url(../images/navbarBG.jpg); */
  background-size: cover;
  opacity: 0;
  z-index: 1;
}
.bg-navbar {
  position: fixed;
  width: 100%;
  z-index: 100;
  background-color: var(--navBG);
  color: var(--navText);
  box-shadow: 0px 6px 14px var(--navShadow);
  margin-bottom: 2rem;
}

.nav-left {
  margin-right: auto;
}

.nav-middle {
  margin: 0 auto;
}

.nav-right {
  margin-left: auto;
}

.m-1 { margin: 1rem; }

.mx-auto { margin-left: auto; margin-right: auto; }
.mx-1 { margin-left: 0.5rem; margin-right: 0.5rem; }

.mt-0 { margin-top: 0; }

.ml-auto {margin-left: auto; }
.ml-05 {margin-left: 0.5rem; }
.ml-1 {margin-left: 1rem; }
.ml-2 {margin-left: 2rem; }
.ml-3 {margin-left: 3rem; }

.mr-auto {margin-right: auto; }
.mr-05 {margin-right: 0.5rem; }
.mr-1 {margin-right: 1rem; }
.mr-2 {margin-right: 2rem; }
.mr-3 {margin-right: 3rem; }

.mt-0 { margin-top: 0.05rem; }
.mt-05 { margin-top: 0.5rem; }
.mt-1 { margin-top: 1rem; }
.mt-2 { margin-top: 2rem; }
.mt-3 { margin-top: 3rem; }

.mb-none { margin-bottom: 0 !important; }
.mb-0 { margin-bottom: 0.05rem; }
.mb-05 { margin-bottom: 0.5rem; }
.mb-1 { margin-bottom: 1rem; }
.mb-2 { margin-bottom: 2rem; }
.mb-3 { margin-bottom: 3rem; }

.p-05 { padding: 0.5rem; }
.p-01 { padding: 0.05rem; }
.p-1 { padding: 1rem; }
.p-2 { padding: 2rem; }
.py { padding-top: 0.5rem; padding-bottom: 0.5rem; }
.py-1 { padding-top: 1rem; padding-bottom: 1rem; }
.py-2 { padding-top: 2rem; padding-bottom: 2rem; }
.px-1 { padding-left: 1rem; padding-right: 1rem; }
.px-2 { padding-left: 2rem; padding-right: 2rem; }
.pt-1 { padding-top: 1rem; }
.pt-05 { padding-top: 0.5rem; }
.pt-0 { padding-top: 0.05rem; }
.pb-1 { padding-bottom: 1rem; }
.pb-2 { padding-bottom: 2rem; }
.pb-05 { padding-bottom: 0.5rem; }
.pb-0 { padding-bottom: 0.05rem; }
.pl-01 { padding-left: 0.5rem; }
.pl-1 { padding-left: 1rem; }
.pr-01 { padding-right: 0.5rem; }
.pr-1 { padding-right: 1rem; }
.center { margin-left: auto; margin-right: auto;}

.container {
  position: relative;
  padding: 8px 12px;
  max-width: 90vw;
  margin-left: auto;
  margin-right: auto;
  /* border: 1px solid #efefef; */
  overflow: hidden;
}
.container-img {
  position: relative;
  padding: 8px 12px;
  max-width: 90vw;
  margin-left: auto;
  margin-right: auto;
  /* border: 1px solid #efefef; */
  overflow: hidden;
}
.container-md {
  position: relative;
  padding: 8px 12px;
  max-width: 1080px;
  margin-left: auto;
  margin-right: auto;
}
.section {
  /* padding: 12px; */
  margin-bottom: 1rem;
  border-radius: 8px;
}

.section-a {
  margin-top: 18px;
  margin-bottom: 4rem;
  border-radius: 8px;
  background-color: #e4e4e43a;
}

.section-b {
  margin-top: 18px;
  margin-bottom: 4rem;
  border-radius: 8px;
}

.pos-relative { position: relative; }
.inline-block { display: inline-block; }
/* .row { width: 100%; } */
.flex-row { 
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.flex-col { 
  display: flex;
  flex-direction: column;
  /* flex-wrap: wrap; */
}
.inline-flex { 
  display: inline-flex;
  align-items: center;
}
.flex-wrap { flex-wrap: wrap; }
.flex-nowrap { flex-wrap: nowrap; }
.align-items-center { align-items: center !important; }
.align-items-start { align-items: flex-start !important; }
.align-items-base { align-items: baseline !important; }
.align-items-end { align-items: end !important; }
.justify-flex-center { justify-content: center; }
.justify-flex-between { justify-content: space-between; }
.justify-flex-around { justify-content: space-around; }
.justify-flex-end { justify-content: flex-end; }
.align-c-center { align-content: center; }
.flex-start { align-content: flex-start; }
.flex-end { align-content: flex-end; }
.self-flex-start { align-self: flex-start; }

.remove-btn { background-color: #c75243 !important; float: left; }
.hover-red:hover { color: #c75243; }
.text-normal { font-weight: normal; }
.text-bold { font-weight: 600; }
.text-italic { font-style: italic; }
.text-camel { text-transform: capitalize !important; }
.text-caps { text-transform: uppercase !important; }

.text-left { text-align: left; }
.text-center { text-align: center; }
.text-right{ text-align: right; }

.text-home { color: var(--navText); text-decoration-color: #43b6da !important; }
.text-link { color: var(--newPostText) }
.text-status { color: #3c6963; background-color: #dcf1e585; border-radius: 2px; }
.text-remail { color: #ce6034; background-color: #f1eedc85; border-radius: 6px; }
.text-status-gray { color: #585147; background-color: #838b862c; border-radius: 2px; }
.text-white { color: #ffffff; }
.text-black { color: #4b4723; text-decoration: none !important; }
.text-red { color: #c75243; }
.text-grey { color: #808080; }
.text-green { color: green; }
.text-info { color: #555; margin-block-start: 0.1rem; }
.text-suspended { 
  color: #8a2b2b;

  font-weight: 500;
  background-color: #fcdfdf;
  border-bottom: 1px solid #eecccc;
  border-radius: 4px;
}












.border-b {
  position: relative;
  border-bottom: 1px solid var(--mainGrey);
}

.small-img {
  height: auto;
  width: 42px;
}
.small-img-bubble {
  height: 42px;
  width: 42px;
  border: 3px solid rgb(218, 218, 218);
  background-color: rgb(246, 246, 246);
  border-radius: 120px;
  object-fit: contain;
}

.z-2 { z-index: 2; position: relative; }

.featured-star { max-width: 2.1rem; }
.rating-star { width: 2.2rem !important; }
.rating-overlay { 
  display: flex; position: absolute;
  align-items: center;
  justify-content: center;

  bottom: 0px;
  right: 0px;
  background-color: #ffffffcc;
  border-radius: 16px 0 0 0;
  width: 120px;
  height: 40px;
  overflow: hidden;
}

.button-n {
  display: inline-block;
  position: relative;
  margin-left: 0.5rem;
  font-family: 'Barlow Condensed', sans-serif; font-weight: 600;

  color: var(--navText);
  background-color: rgb(246, 246, 246);
  padding: 8px 42px 4px 42px;
  border-color: rgb(49, 49, 49);
  border-radius: 20px;
  border-style: none;
  border-width: 1px;
  text-decoration: none !important;
  cursor: pointer;
}
.button-n:hover {
  transform: scale(1.005);
  color: #ffffff !important;
  background-color: var(--navButton);
}

.button-search {
  display: inline-block;
  position: relative;
  margin-left: 0.1rem;

  color: white;
  background-color: rgb(49, 49, 49);
  padding: 8px 28px;
  border-radius: 20px;
  border-style: solid;
  border-width: 0;
  text-decoration: none !important;
  cursor: pointer;
}
.button-search:hover {
  transform: scale(1.005);
  color: #ffffff !important;
  border: 0 0 0;
  background-color: var(--navButton);
}
.button-filter {
  display: inline-block;
  position: relative;

  color: var(--navText);
  border: 0;
  background-color: transparent;
  padding: 4px 4px;
  text-decoration: none !important;
  cursor: pointer;
}
.button-filter:hover {
  transform: scale(1.01);
}

.property-thumb ul {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(360px, max-content));
  grid-gap: 16px;
  justify-content: center;
  padding: initial;
}

.property-thumb li {
  position: relative;
  list-style-type: none;
  /* border: 1px solid gray; */
  /* padding: 5px; */
  width: 360px;
}
.property-thumb li:hover {
  transform: scale(1.05);
  cursor: grab;
}

.rental-box {
  display: block;
  position: relative;
  text-align: center;
  font-family: inherit;

  padding: 0;
  min-width: 340px;
  width: 360px;
  max-width: 360px;
  min-height: 420px;
  height: 420px;
  max-height: 420px;
  /* box-shadow: 0px 6px 14px var(--navShadow); */
  border: 1px solid #cacaca;
  border-radius: 12px;
  background-color: white;
  /* margin: 2px 22px; */
  overflow: hidden;
  box-shadow: 0 6px 7px 0 rgba(0,0,0,.04);
}
.rental-box:hover {
  /* transform: scale(1.05); */
  cursor: pointer;
}

.property-thumb-acc ul {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(166px, max-content));
  grid-gap: 16px;
  justify-content: center;
  padding: initial;
}

.property-thumb-acc li {
  position: relative;
  list-style-type: none;
  width: 166px;
}
.property-thumb-acc li:hover {
  transform: scale(1.05);
  cursor: grab;
}

.rental-box-acc {
  display: block;
  position: relative;
  text-align: center;
  font-family: inherit;

  padding: 0;
  min-width: 166px;
  width: 166px;
  max-width: 166px;
  min-height: 226px;
  height: 226px;
  max-height: 240px;
  /* box-shadow: 0px 6px 14px var(--navShadow); */
  border: 1px solid #cacaca;
  border-radius: 12px;
  background-color: white;
  /* margin: 2px 22px; */
  overflow: hidden;
  box-shadow: 0 6px 7px 0 rgba(0,0,0,.04);
}
.rental-box-acc:hover {
  /* transform: scale(1.05); */
  cursor: grab;
}

.half-vert-img {
  display: block;
  position: relative;
  flex-grow: 1;
  width: 100%;
  height: 60%;
  background-color: var(--navButton);
}
.half-vert-img img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.half-vert {
  display: flex;
  flex-direction: column;
  position: relative;
  flex-grow: 1;
  width: 100%;
  height: 40%;
  background-color: #f8f8f8;
}

.bg-main { background-color: var(--mainBG); min-height: 56.1vh; } /* #fdfaee */
.blend-nav-main {
  width: 100%;
  height: 40px;
  background-image: linear-gradient(var(--navBG), var(--mainBG));
}
.blend-main-footer {
  width: 100%;
  height: 40px;
  background-image: linear-gradient(var(--mainBG), var(--footerBG));
}
.footer {

  padding: 4px 12px;
  color: #969183;
  background-color: var(--footerBG);
}

.App-logo {
  padding: 2px;
  height: 48px; width: auto;
  /* filter: invert(90%) sepia(16%) saturate(465%) hue-rotate(357deg) brightness(98%) contrast(87%); */
  pointer-events: none;
}

/* .btn {
  font-family: 'Source Sans Pro';
  padding: 6px 18px;
  background-color: transparent;
  border: 1px solid #4c9ca1;
  border-radius: 4px;
  cursor: pointer;

} */

/* MODAL VARIABLES BEGIN */
.modal {
  display: block;
  position: fixed;
  top: 0; left: 0;
  font: inherit;
  text-align: inherit;
  width: 100%; height: 100%;
  background-color: rgba(0, 0, 0, 0.4) !important;
  z-index: 999;
}
.modal-bg {
  position: absolute;
  top: 0; left: 0;
  width: 100%; height: 100%;
  color: transparent !important;
  background-color: transparent !important;
  border: none;
  outline: none;
  z-index: 1000;
}
.modal-content-payment {
  position: relative;
  top: 50%;
  color: var(--navText);
  text-shadow: none;
  background-color: #fff;
  padding: 12px;
  min-height: 220px;
  max-width: 520px; max-height: 620px;
  border-radius: 8px;
  margin: auto;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 1001;
  transform: translateY(-50%);
}
.content-payment {
  position: relative;
  color: var(--navText);
  text-shadow: none;
  background-color: #fff;
  padding: 12px;
  min-height: 220px;
  max-width: 520px; max-height: 620px;
  border-radius: 8px;
  margin: auto;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 1001;
}
.modal-content {
  position: relative;
  top: 50%;

  color: var(--navText);
  text-shadow: none;
  background-color: #fff;
  padding: 12px;
  min-height: 220px;
  max-width: 520px; max-height: 620px;
  border-radius: 8px;
  margin: auto;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 1001;
  transform: translateY(-50%);
}
.modal-btn {
  display: block;
  padding: 6px 18px;
  background-color: #fff;
  border: 1px solid #4c9ca1;
  border-radius: 4px;
  cursor: pointer;
  margin: auto;
}
.modal-exit {
  top: 12px;
  right: 6px;
  position: absolute;
  display: block;
  padding: 2px 6px;
  background-color: transparent;
  border: 1px solid #4c9ca1;
  border: 0;
  border-radius: 4px;
  cursor: pointer;
  z-index: 99;
}
.modal-exit img {
  width: 36px;
}

.confirm-modal-btn {
  font-family: 'Source Sans Pro';
  padding: 6px 18px;
  background-color: #fff;
  border: 1px solid #4c9ca1;
  border-radius: 4px;
  cursor: pointer;
}

.confirm-modal-form {
  text-align: center;
  padding: 8px;
}

.confirm-modal-form * { font-family: 'Source Sans Pro'; }

.confirm-modal-form textarea {
  width: 100%;
  padding: 10px;
  border: 1px dashed lightgrey;
  border-radius: 12px;
}

.confirm-modal-form input[type="range"] {
  filter: contrast(0.5)hue-rotate(148deg)brightness(1.5)
}

.confirm-modal-form .rating-star {
  cursor: pointer;
}

.errors-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: rgb(131, 29, 29);
  min-height: 120px;
}
.errors-container div::before {
  content: "• ";
}

.messages-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: rgb(0, 0, 0);
  min-height: 120px;
}
.subscriber-divider {
  height: 10px;
  width: 100%;
  background-color: rgba(212, 228, 228, 0.133);
  border-radius: 12px;
}
.subscriber-plan {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  padding: 24px;
  border: 1px solid rgb(240, 240, 240);
  border-radius: 6px;
  background-color: rgb(250, 250, 250);
  min-width: 300px;
  /* max-width: 300px; */
  box-shadow: 2px 6px 8px 5px gainsboro;
}

.premium {
  background-color: rgb(245, 234, 214);
  box-shadow: 2px 6px 8px 5px rgb(209, 199, 178);
}

.red-filter {
  filter: invert(26%) sepia(4%) saturate(7442%) hue-rotate(316deg) brightness(113%) contrast(93%);
}

.subscriber-plan button {
  font-family: 'Source Sans Pro';
  font-weight: 500;

  color: white;
  background-color: var(--newPostText);
  border: none;
  border-radius: 4px;
  padding: 6px 8px;
}

.premium button {
  background-color: rgb(217, 144, 81);
}

.subscriber-plan ul {

  list-style: none;
  margin: 0;
  padding: 0;
}

/* MODAL VARIABLES END */

.logo-btn {
  color: #ffffff !important;
  background-color: var(--navButton);
  padding: 8px 82px;
  /* border: 2px solid #c7d1d265; */
  border-radius: 22px;
  text-decoration: none !important;
}
.logo-btn:hover { transform: scale(1.005);  }

.overlay-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 96;
}
.overlay-bg-dark {
  top: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 96;
  background-color: #00000055;
}
.overlay-bg-gradient {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 96;
  /* background-color: #00000045; */
  background-image: linear-gradient(var(--navBG), var(--navShadow));
  opacity: 80%;
}
.header-overlay {
  position: absolute;
  z-index: 98;
  color: white;
}

.parent-box {
  display: block;
  position: relative;
  text-align: center;
  width: 100%;
  margin: 4px;
}

.half-horizontal {
  display: inline-block;
  position: relative;
  width: 48%;
  height: 98%;
}
.half-horizontal-img {
  display: inline-block;
  position: relative;
  order: 1;
  width: 100%;
  height: 100%;
  /* background-image: url(https://images.unsplash.com/photo-1548574505-5e239809ee19?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2764&q=80); */
  background-image: url('../images/home/home-bg.jpg');
  background-position: center;
  background-position-y: 24%;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: gray;
  border-radius: 121px;
  box-shadow: 0px 6px 14px var(--navShadow);
}
.nav-shadow { box-shadow: 0px 6px 14px var(--navShadow); }
.property-search-overlay {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  /* bottom: 180px; */
  /* left: 0; */
}
.property-search-box {

  padding: 18px 18px;
  border: 1px solid rgb(183, 183, 183);
  border-radius: 24px;
}

.home-header {
  font-family: 'Source Sans Pro';
  letter-spacing: -3px;
  font-size: 4.5rem;
  max-width: 800px;
  /* line-height: 34px; */
  line-height: 44px;
  margin-top: 3.5rem;
  margin-bottom: 0.4rem;
  text-shadow: 7px 5px 8px #0000006c;
  /* text-align: left; */
}
.sub-header { 
  font-family: 'Alegreya', 'Source Sans Pro';
  font-weight: 500 !important;

}

/* #PropertyThumb {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
} */

#navDropDownMenu{
  position: absolute;
  min-width: 240px;
  padding: 12px 26px;
  left: 138px;
  top: 90px;
  background-color: var(--navBG);
  z-index: 99;

  /* border-top: 2px solid rgb(206, 206, 206); */
  box-shadow: 1px 8px 14px rgba(53, 53, 53, 0.322);
  border-radius: 2px 2px 8px 8px;
}

#navBackDrop {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 30%;
  z-index: 98;
  pointer-events: all;
  top: 0;
  left: 0;
}

#navBackDrop:hover {
  transform: none; cursor: default;
}

.ad {
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
  padding: 12px;
  border-radius: 12px;
  border: 1px solid rgb(232, 232, 232);
  background-color: rgb(245, 245, 245);
  overflow: hidden;
}

.ad img {
  max-width: 560px;
  height: auto;
  border-radius: 6px;
  object-fit: contain;
}

.ad p {
  display: block;
  max-width: 560px;
  padding: 6px 12px;
}

.ad span {
  display: inline-block;
}

.ad span.a {
  text-indent: 12px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .subscriber-plan {
    margin-right: 0;
    margin-bottom: 1rem;
  }

  .text-center-sm { text-align: center !important; justify-content: center !important; }

  .small-img {
    height: auto;
    width: 30px;
  }

  .nav-search-form {
    height: 30px !important;
  }

  .nav-search-bar {

    width: 220px !important;
  }

  .nav-button-search {

    font-weight: normal !important;
  }

  .home-h1 {
    border-radius: 12px !important;
  }

  .rental-box {
    min-width: 320px;
    width: 320px;
    max-width: 340px;
    min-height: 320px !important;
    height: 320px !important;
    max-height: 340px !important;
  }

  .property-thumb-acc ul {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, max-content));
    grid-gap: 10px;
    justify-content: center;
    padding: initial;
  }
  
  .property-thumb-acc li {
    position: relative;
    list-style-type: none;
    width: 150px;
  }
  .property-thumb-acc li:hover {
    transform: scale(1.05);
    cursor: grab;
  }
  
  .rental-box-acc {
    display: block;
    position: relative;
    text-align: center;
    font-family: inherit;

    padding: 0;
    min-width: 150px;
    width: 150px;
    max-width: 150px;
    min-height: 180px;
    height: 180px;
    max-height: 200px;
    /* box-shadow: 0px 6px 14px var(--navShadow); */
    border: 1px solid #cacaca;
    border-radius: 12px;
    background-color: white;
    /* margin: 2px 22px; */
    overflow: hidden;
    box-shadow: 0 6px 7px 0 rgba(0,0,0,.04);
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
/* @media only screen and (min-width: 600px) {
} */

/* Small devices (landscape tablets, phones, 768px and down) */
@media (max-width: 768px) {




  .rating-star { width: 1.6rem !important; }


  .container {
    padding: 4px;
    max-width: 98vw;
  }
  .container-img {
    padding: 0;
    max-width: 100vw;
  }

  .nav-search-form {
    height: 38px;
    margin-top: 1.4em;
  }
  .nav-search-bar {
    width: 50vw;
  }
  .nav-button-search {

    font-weight: bold;
  }

  #navDropDownMenu{
    position: absolute;
    /* width: 100%; */
    padding: 0 8px;
    left: 19px;
    top: 46px;
    background-color: var(--navBG);
    z-index: 99;

    border-bottom: 2px solid rgb(237, 170, 37);
  }

  .text-center-sm { text-align: inherit; justify-content: inherit; }
  .hide-sm { display: none !important; }
  /* .hide-md { display: none; } */
  /* .hide-lg { display: block; } */
  .logo-btn {
    color: #ffffff !important;
    background-color: var(--navButton);
    padding: 8px 12px;
    border-radius: 22px;
    text-decoration: none !important;
  }
  .App-logo { height: 45px; }

  /* .bg-navbar {
    box-shadow: none;
    border-bottom: 1px solid var(--navButton);
  } */

  .nav-shadow-sm { box-shadow: 0px 6px 14px var(--navShadow); }
  .featured-star { max-width: 1.8rem; }

  .parent-box {
    display: block;
    height: 20rem;
    margin: 0;
  }
  .half-horizontal {
    order: 2;
    width: 100%;
    height: 98%;
  }
  .half-horizontal-img {
    order: 1;
    width: 100%;
    height: 100%;
    border-radius: 0;
  }

  .property-search-overlay {
    position: absolute;
    bottom: 180px;
    left: 0;
    z-index: 97;
  }
  .property-search-box {

    padding: 8px 18px;
    background-color: rgba(255, 255, 255, 0.729);
    border: 1px solid rgb(183, 183, 183);
    border-radius: 24px;
  }

  .search-btn {
    width: 60vw;

    color: rgb(0, 0, 0);
    background-color: #ffffff;
    /* border: 1px solid var(--navButtonGold); */
    border-radius: 4px;
  }
  .button-search {
    margin-left: 4px;

    color: white;
    background-color: var(--navButtonGold);
    padding: 4px 12px;
    border-radius: 6px;
    border-style: solid;
    border-width: 0;
    text-decoration: none !important;
    cursor: pointer;
  }

  .home-header {
    font-size: 2.8rem;
    /* max-width: 500px; */
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    text-transform: capitalize;
  }



  .property-thumb ul {
    grid-template-columns: repeat(auto-fit, minmax(320px, max-content));
  }
  .property-thumb li {
    width: 320px;
    margin-bottom: 12px;
  }
  .rental-box {
    min-width: 320px;
    width: 320px;
    max-width: 340px;
    min-height: 360px;
    height: 360px;
    max-height: 360px;
  }
}

/* Medium devices (laptops/desktops, 992px and up) */
@media (min-width: 769px) and (max-width: 1199px) {

  .App-logo { height: 56px; }
  .text-center-sm { text-align: inherit; justify-content: inherit; }
  /* .hide-sm { display: block; } */
  .hide-md { display: none !important; }
  /* .hide-lg { display: block; } */
  .px-1 { padding-left: 0.9rem; padding-right: 0.9rem; }
  .pt-1 { padding-top: 0.9rem; }
  .pl-01 { padding-left: 0.2rem; }
  .pl-1 { padding-left: 0.9rem; }
  .pr-01 { padding-right: 0.2rem; }
  .pr-1 { padding-right: 0.9rem; }
  .container { padding: 8px 4px; }
  .container-img {
    padding: 0;
    max-width: 100vw;
  }


  .nav-search-form {
    height: 44px;
    margin-top: 1.4em;
  }
  .nav-search-bar {
    width: 50vw;
  }
  .nav-button-search {

    font-weight: bold;
  }

  .parent-box {
    display: block;
    height: 22rem;
    margin: 0;
  }
  /* .rental-box {
    margin: 11px 6px;
  } */

  .half-horizontal-img {
    order: 1;
    width: 100%;
    height: 100%;
    border-radius: 0;
  }

  .search-btn {
    width: 420px;

    color: rgb(0, 0, 0);
    background-color: #ffffff;
    border: 1px solid var(--navButtonGold);
    border-radius: 4px;
  }
  .button-search {
    margin-left: 2px;

    color: white;
    background-color: var(--navButtonGold);
    padding: 4px 12px;
    border-radius: 6px;
    border-style: solid;
    border-width: 0;
    text-decoration: none !important;
    cursor: pointer;
  }

  #navDropDownMenu{
    position: absolute;
    /* width: 100%; */
    padding: 0 8px;
    left: 58px;
    top: 64px;
    background-color: var(--navBG);
    z-index: 99;

    border-bottom: 2px solid rgb(237, 170, 37);
  }

  .property-thumb ul {
    grid-template-columns: repeat(auto-fit, minmax(290px, max-content));
  }
  .property-thumb li {
    width: 290px;
    margin-bottom: 22px;
  }
  .rental-box {
    min-width: 290px;
    width: 290px;
    max-width: 290px;
    min-height: 380px;
    height: 380px;
    max-height: 380px;

  }
}

/* Large devices (large laptops and desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 2559px) {

  .App-logo { height: 58px; }

  /* .hide-sm { display: block; } */
  /* .hide-md { display: block; } */
  .hide-lg { display: none !important; }
  .ml-1-lg {margin-left: 0.9rem; }


  .bg-navbar { position: fixed; margin-bottom: 0; }

  .nav-search-form {
    height: 44px;
    margin-top: 1.4em;
  }
  .nav-search-bar {
    width: 50vw;
  }
  .nav-button-search {

    font-weight: bold;
  }
  #navDropDownMenu{
    padding: 0 8px;
    left: 74px;
    top: 72px;

  }

  .container-img {
    position: relative;
    padding: 0;
    max-width: 100vw;
    margin-left: auto;
    margin-right: auto;
    /* border: 1px solid #efefef; */
    overflow: hidden;
  }

  .parent-box {
    display: block;
    width: 100%;
    /* height: 24rem; */
    height: 30vmax;
    /* margin: 2px 22px; */
  }

  .half-horizontal-img {
    order: 1;
    width: 100%;
    height: 100%;
    border-radius: 0;
  }

  .home-header {
    font-size: 4.4rem;

    max-width: none;
  }

  .home-h1 {
    position: absolute;
    z-index: 97;
    color: white;
    left: 50%;
    /* right: 50%; */
    top: 13%;
    transform: translateX(-50%);
  }

  .property-thumb ul {
    grid-template-columns: repeat(auto-fit, minmax(312px, max-content));
  }
  .property-thumb li {
    width: 312px;
    margin-bottom: 22px;
  }
  .rental-box {
    min-width: 312px;
    width: 312px;
    max-width: 312px;
    min-height: 412px;
    height: 412px;
    max-height: 412px;
  }
}

/* Extra large devices (large laptops and desktops, 2560px and up) */
@media (min-width: 2560px) {


  .App-logo { height: 68px; }
  /* .hide-sm { display: block; } */
  /* .hide-md { display: block; } */
  .hide-xl { display: none !important; }
  .p-1-xl { padding: 1rem; }

  .bg-navbar { position: fixed; margin-bottom: 0; }

  .nav-search-form {
    height: 70px;
    margin-top: 3em;
  }
  .nav-search-bar {
    width: 50vw;
  }
  .nav-button-search {

    font-weight: bold;
  }

  .container-img {
    position: relative;
    padding: 0;
    max-width: 100vw;
    margin-left: auto;
    margin-right: auto;
    /* border: 1px solid #efefef; */
    overflow: hidden;
  }

  .parent-box {
    display: block;
    width: 100%;
    height: auto;
    /* margin: 2px 22px; */
    /* margin-top: -2rem; */
  }

  .half-horizontal-img {
    width: 100%;
    height: 800px;
    border-radius: 0;
  }

  .home-header {
    font-size: 5.5rem;
    max-width: none;
    margin-top: 1.5em;
    margin-bottom: 0.35em;
    font-weight: 700;
  }

  .home-h1 {
    position: absolute;
    z-index: 97;
    color: white;
    left: 50%;
    /* right: 50%; */
    top: 8%;
    transform: translateX(-50%);
  }

  .rental-box {
    min-height: 412px;
    height: 412px;
    max-height: 412px;
  }
}

.hide {
  display: none;
}

#mobileNav {
  /* position: absolute; */
  border: none;
  background: none;

}


#navDropDownMenu a{
  display: block;
  /* color: var(--navButtonGold); */
  color: #201c16;
  /* border-bottom: 1px solid rgb(207, 207, 207); */
  padding: 16px;
  border-radius: 8px;
  text-decoration: none;
}

#navDropDownMenu a:last-child{
  border-bottom: none;
}

#navDropDownMenu a:focus{
  border: 1.2px dotted #CCC;
}

#navDropDownMenu a:hover{
  background-color: #1818180e;
  color: #e99c0d !important;
}

/* .home-h1 {
  background-color: #00000028;
  border-radius: 299px 299px 0 0;
} */

.slogan {
  font-family: 'Poppins';
  font-weight: 380;
  letter-spacing: -1px;
  text-shadow: 7px 5px 8px #00000083;
}
