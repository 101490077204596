.transact-table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #ddd;
}

.transact-table th, .transact-table td {
  text-align: left;
  padding: 8px;
}

.transact-table tr:nth-child(even){background-color: #f2f2f2}

.transact-table tr > th {
  text-transform: capitalize;
  /* padding: 2px; */
  vertical-align: bottom;
}

/* .transact-table tr > th {
  padding-left: 6px;
}

.transact-table tr > td {
  padding-left: 6px;
} */
