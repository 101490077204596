.loader {
  margin: auto;
  width: 180px;
  height: 180px;
  border: 18px solid #ffb9b9;
  border-radius: 50%;
  border-right: 18px solid #3e6470;
  animation: logo-spin infinite 20s linear;
  text-align: center;
}

.p-loader { padding: 2.5em; }

@keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}