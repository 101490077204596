.checkboxDiv {
  display: block;
  padding: 1px !important;
  border-bottom: 1px solid #e0e0e0 !important;
  border-right: 1px solid #e0e0e0 !important;
  border-radius: 12px;
}

.checkbox {
  display: inline-block;
  /* font-size: 0.7em; */
  font-family: 'Barlow Semi Condensed', sans-serif;
  color: #ffffff;
  min-width: 44px !important;
  padding: 4px !important;
  border: none !important;
  border-radius: 12px;
  cursor: pointer;
}

.checkbox[checkedvalue="false"] {
  /* font-size: 0.7em; */
  font-family: 'Barlow Semi Condensed', sans-serif;
  color: #ffffff;
  border: 1px solid var(--navButtonGold);
  background-color: grey !important;
}

.checkbox[checkedvalue="true"] {
  /* font-size: 0.7em; */
  font-family: 'Barlow Semi Condensed', sans-serif;
  color: #ffffff;
  border: 1px solid var(--navButtonGold);
  background-color: var(--navButtonGold) !important;
}