
.contactUsBG {
  /* background-color: rgb(22, 163, 206); */
  background-color: rgb(130, 130, 130);
  background-image: url(../images/home/contactUsBg3.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 90vh;
}

.contactUsForeground{
  background-color: rgba(255, 255, 255, 0.85);
  padding: 12px 44px;
  padding-bottom: 32px;
  margin-top: 6em;
  border-radius: 22px;
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.291);
  max-width: 500px;
  min-height: 200px;
  /* text-align: center; */
}

.aboutUsBG {
  /* background-color: rgb(22, 163, 206); */
  background-color: rgb(37, 37, 37);
  background-image: url(../images/home/termsBG2.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  min-height: 90vh;
}

.aboutUsForeground{
  background-color: rgba(255, 255, 255, 0.85);
  padding: 12px 44px;
  padding-bottom: 32px;
  margin-top: 3em;
  border-radius: 22px;
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.291);
  max-width: 1000px;
  /* text-align: center; */
}

/* Small devices (landscape tablets, phones, 424px and down) */
@media (max-width: 424px) {
  .contactUsBG {
    background-position: 80%;
    background-size: cover;
  }
  .aboutUsBG {
    background-position: 70%;
    background-size: cover;
  }
  .aboutUsForeground{
    padding: 12px 24px;
  }
}

@media (max-width: 620px) {
  .contactUsBG {
    background-position: 80%;
    background-size: cover;
  }
  .aboutUsBG {
    background-position: 70%;
    background-size: cover;
  }
  .aboutUsForeground{
    padding: 12px 24px;
  }
}
/* Small devices (laptops/desktops, 424px and up to 770px) */
@media (min-width: 425px) and (max-width: 768px) {
  .contactUsBG {
    background-position: 80%;
    background-size: cover;
  }
  .aboutUsBG {
    background-position: 70%;
    background-size: cover;
  }
}

/* Medium devices (laptops/desktops, 770px and up to 1024px) */
@media (min-width: 769px) and (max-width: 1024px) {
  .contactUsBG {
    background-position: 80%;
    background-size: cover;
  }
  .aboutUsBG {
    background-position: 70%;
    background-size: cover;
  }
}

/* Medium devices (laptops/desktops, 1025px and up) */
@media (min-width: 1025px) and (max-width: 1199px) {

}

/* Large devices (large laptops and desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 2559px) {

}

/* Extra large devices (large laptops and desktops, 2560px and up) */
@media (min-width: 2560px) {

}