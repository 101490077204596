.loginBG {
  /* background-color: rgb(22, 163, 206); */
  background-color: rgb(37, 37, 37);
  background-image: url(../images/home/loginBG.jpg);
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  min-height: 90vh;
}

.forgotPassBG {
  /* background-color: rgb(22, 163, 206); */
  background-color: rgb(37, 37, 37);
  background-image: url(../images/home/passwordForgot.jpg);
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  min-height: 90vh;
}

.login-img {
  position: relative;
  width: 60%;
  height: 88vh;
  background-attachment: fixed;
  background-image: url(../images/home/housing.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 1px 8px 14px 3px whitesmoke;
}
.login-img img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.register-as {
 display: flex;
 flex-direction: column;
 align-items: center;
}

.register-as button {
  display: block;
  width: 140px;
  height: 140px;
  background-color: var(--navButtonGold);
  border: 0;
  border-radius: 6px;
}

.register-as button:hover {
  background-color: rgb(243, 232, 202);
  border-color: var(--navButtonGold);
}

.register-as button > img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.register-flex {
  display: flex;
  position: relative;
  height: 88vh;
  flex-wrap: nowrap;
  overflow: hidden;
}
.register-text {
  display: flex;
  color: #fff;
  position: absolute;
  top: 0;
  padding: 12px;
  height: 100%;
  width: 100%;
  z-index: 98;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
}
.register-text > * { color: white; }


.register-form {
  /* border: 1px solid whitesmoke; */
  box-shadow: 1px 8px 14px 3px whitesmoke;
}

.form-sub-text {

  margin-left: 4px;
}

/* form h1, form h2, form h3, form h4, form h5, form h6 {
  font-family: 'Barlow Semi Condensed', cursive;
} */
#LoginPage {
  min-height: 85vh;
}

#LoginPage .login-form {
  display: flex;
  flex-direction: column;
  max-width: 540px;
  margin: auto;
  margin-top: 3em;
  border-radius: 8px;
  padding: 16px;
  background-color: #ffffffea;
  box-shadow: 2px 12px 14px 8px #ffffffea;
}

.limit-width {
  max-width: 540px;
  margin: auto;
  margin-top: 3em;
  border-radius: 8px;
  padding: 16px;
  background-color: #ffffffea;
  box-shadow: 2px 12px 14px 8px #ffffffea;
}

#LoginPage .login-form input {
  padding: 8px;
  border: 1px solid #e9e7e7;
  /* border-radius: 12px; */
  outline-color: #3a425c;

  margin-bottom: 16px;
}
#LoginPage .login-form input[type='tel'] {
  margin-bottom: 0;
}
#LoginPage .login-form select {
  padding: 8px;
  border: 1px solid #e9e7e7;
  border-radius: 12px;

  outline-color: #3a425c;
  margin-bottom: 12px;
}
#LoginPage .login-form select:required {
  border: 1px solid rgb(248, 151, 55);
}

/* #LoginPage .login-form input[type="checkbox"] {
  margin-right: auto;
  border-color: #860303;
} */

#LoginPage .login-form input:required {
  border: 1px solid rgb(248, 151, 55);
}

#LoginPage .login-form textarea {
  padding: 8px;
  border: 1px solid #e9e7e7;
  border-radius: 12px;

  outline-color: #3a425c;
  margin-bottom: 1em;
  min-height: 120px;
}

#LoginPage .login-form button {

  font-family: 'Barlow Semi Condensed', sans-serif;
  color: #453c0e;
  /* padding: 8px; */
  border: 1px solid var(--navButtonGold);
  background-color: var(--navButtonGold);
  /* border-radius: 12px; */
  cursor: pointer;
}

#LatestUser {
  color: #555;
}

.members {
  padding: 2px 6px;
  color: #364b50;
  text-transform: capitalize;
}
.allUsers {
  padding: 8px;
  max-width: 320px;
  text-align: center;
  border: 1px solid #aaaaaa41;
  border-radius: 12px;
  margin: 12px;
  cursor: pointer;
  color: var(--postText);
}

#AllUsers .profile-pic {
  width: auto;
  height: auto;
  max-height: 100px;

  padding: 12px;
  border: 2px solid #555;
  border-radius: 22px;
  margin: auto;
}

.user {
  padding: 2px 6px;
  color: var(--authorColor);
  cursor: pointer;
  text-transform: capitalize;
}
.user:hover {
  text-decoration: underline;
  transform: scale(1.025);
}

#LatestUser .user::after {
  content: ", ";
}

#LatestUser .user:last-of-type::after {
  content: "";
}

#UserProfile .profile-pic {
  width: auto;
  height: auto;
  max-height: 200px;

  padding: 12px;
  border: 2px solid #555;
  border-radius: 22px;
  margin: auto;
}

#UserProfile .latest-comments {
  display: flex;
  flex-wrap: wrap;
}
#UserProfile .latest-comments > button {

  padding: 4px 8px;
  text-align: left;
  box-shadow: 2px 6px #927d6434;
  border-width: 1px;
  border-radius: 2px;
  margin: 2px 6px;
  cursor: pointer;
}

.login-btn {

  /* font-weight: 500; */
  color: #151514;
  background-color: transparent;
  padding: 4px 6px;
  /* border: 2px solid #c7d1d265; */
  border-radius: 8px;
  /* margin-left: 22px; */
  text-decoration: none !important;
  cursor: pointer;
}
.login-btn:hover {
  transform: scale(1.02);
}
.login-btn i {
  color: #cb8b83;
  margin-right: 4px;
}

.image-btn {

  font-weight: 500;
  color: #224b41;
  background-color: transparent;
  padding: 1px 6px;
  border: 2px solid #6fdbc0;
  border-radius: 14px;
  /* margin-left: 22px; */
  text-decoration: none !important;
  cursor: pointer;
}
.image-btn:hover {
  transform: scale(1.02);
}
.image-btn i {
  color: #f3705f;
  margin-right: 4px;
}

#AdminPanel {
  padding: 8px;
  color: #364b3f;
  /* text-shadow: 0 0 1px #283d50; */
  background-color: #dcf1e585;
  border-radius: 6px;
}
#AdminPanel button {
  font-family: 'Hind', sans-serif;
  padding: 2px 8px;
  color: #fff;
  background-color: #9ab8ca;
  border-width: 1px;
  border-color: #4b5b64;
  border-radius: 4px;
  box-shadow: 2px 4px rgba(0, 0, 0, 0.05);
}

.modal-form {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 420px;
  margin: auto;
}
.modal-form input, .modal-form select {
  font-family: 'Hind', sans-serif;
  padding: 4px 8px;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline-color: #3a425c;
  margin-bottom: 12px;
}
.modal-form button {
  font-family: 'Hind', sans-serif;
  color: #eeeeee;
  padding: 6px 12px;
  max-width: 120px;
  background-color: #414655;
  border-radius: 4px;
  margin-left: auto;
  cursor: pointer;
}

.all-forums {
  display: flex;
  flex-wrap: wrap;
}
.forum {
  padding: 12px;
  border-right: 2px solid #70601752;
  border-bottom: 1px solid #70601742;
  border-radius: 8px;
  margin: 4px;
}
.forum-menu {
  display: flex;
  flex-wrap: wrap;
  margin-top: 6px;
}
.forum-menu button {
  padding: 2px 4px;
  color: #ffffff !important;
  background-color: #636260 !important;
  border: 1px solid #ddcfbe;
  border-radius: 6px;
  margin: 2px 2px;
  cursor: pointer;
}
.subforum { font-weight: 500; text-transform: capitalize; margin-right: 2px; }
.subforum::after {
  content: ", ";
}
.subforum:last-of-type::after {
  content: "";
}

/* Medium devices (landscape tablets, 768px and down) */
@media (max-width: 768px) {
  .login-img {
    position: relative;
    width: 100%;
    height: 36vmax;
    /* height: 440px; */
    margin-bottom: 0.8em;
  }
  .register-flex {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    height: auto;
    /* flex-wrap: wrap; */
    overflow: visible;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media (min-width: 769px) and (max-width: 1024px) {
  .register-flex {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    height: auto;
    flex-wrap: wrap;
    overflow: visible;
  }
  .login-img {
    width: 100%;
    height: 42vmax;
    margin-bottom: 0.8em;
  }
}

/* Medium devices (laptops/desktops, 1025px and up) */
@media (min-width: 1025px) and (max-width: 1199px) {
  .register-flex {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    height: auto;
    flex-wrap: wrap;
    overflow: visible;
  }
  .login-img {
    width: 100%;
    height: 42vmax;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 2559px) {
  /* #LoginPage { margin-top: 2em; } */

  .login-img {
    width: 74%;
  }
}

/* Extra Extra large devices (large laptops and desktops, 2560px and up) */
@media only screen and (min-width: 2560px) {
  /* #LoginPage { margin-top: 2em; } */

}


.overflow-scroll {
  overflow-y: scroll;
}