
.googlemaps {
  width: 100%;
  height: 300px;
}

/* Small devices (landscape tablets, phones, 768px and down) */
@media only screen and (max-width: 768px) {
  .googlemaps {
    height: 300px;
  }

}

/* Medium devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 769px) and (max-width: 1199px) {

}

/* Large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) and (max-width: 2559px) {

}

/* Extra large devices (large laptops and desktops, 2560px and up) */
@media only screen and (min-width: 2560px) {
  .googlemaps {
    height: 400px;
  }
}