#AccountPage {

  background-color: var(--mainGrey);
  min-height: 90vh;
}

.acct-btn {
  display: block;
  position: relative;
  margin-left: 0.5em;
  margin-bottom: 8px;

  color: rgb(20, 20, 20);
  background-color: transparent;
  padding: 8px 32px;
  border-radius: 20px;
  border-style: solid;
  border-width: 0;
  text-decoration: none !important;
  cursor: pointer;
}
.acct-btn:hover {
  transform: scale(1.005);
  color: #000000 !important;
  background-color: var(--acctButton);
}

.selected-acct-btn {
  color: #000000 !important;
  background-color: var(--acctButton);
}

.acct-container {
  display: flex;
  flex-wrap: wrap;
  padding: 40px 0;
}

.acct-nav-container {
  /* border: 1px solid grey; */
  margin-right: 2em;
  background-color: var(--mainBG);
  border-radius: 22px;
  padding: 24px;
  border: 1px solid #dfe0e4;
  box-shadow: 2px 12px 14px 8px rgb(229, 225, 225);
}

.acct-menu-container {
  /* border: 1px solid grey; */
  background-color: var(--mainBG);
  border-radius: 22px;
  padding: 14px;
  border: 1px solid #dfe0e4;
  box-shadow: 2px 12px 14px 8px rgb(229, 225, 225);
}

.profile-bubble {
  width: 140px;
  height: 140px;
  border: 4px solid rgb(218, 218, 218);
  background-color: rgb(246, 246, 246);
  border-radius: 120px;
  object-fit: contain;
}

.nib-img {
  width: 180px;
  height: 100px;
  border: 4px solid rgb(218, 218, 218);
  background-color: rgb(246, 246, 246);
  border-radius: 12px;
  object-fit: contain;
}

.account-form {
  padding: 22px 4px 32px 4px;
  margin-bottom: 1em;
  border-bottom: 1px solid lightgrey;
  max-width: 600px;
}
.account-form .form-sub-text{

}

.account-form input {
  padding: 8px;
  border: 1px solid var(--navButton);
  border-radius: 12px;
  outline-color: #3a425c;

  margin-bottom: 16px;
}
.account-form input[type="time"] {
  padding: 8px;
  border: 1px solid var(--navButton);
  border-radius: 12px;
  outline-color: #3a425c;

  margin-bottom: 0 !important;
}
.time-slot {
  padding: 3px 8px;
  border: 0;
  border-radius: 8px;
  max-width: fit-content;
  color: white;
  background-color: var(--newPostText);
}
.account-form .PhoneInput{
  margin-bottom: 16px;
}
.account-form .PhoneInputInput{
  margin-bottom: 0;
}
.account-form textarea {
  padding: 8px;
  border: 1px solid var(--navButton);
  border-radius: 12px;

  outline-color: #3a425c;
  margin-bottom: 1em;
}
.account-form select {
  padding: 8px;
  border: 1px solid var(--navButton);
  border-radius: 12px;

  outline-color: #3a425c;
  margin-bottom: 12px;
}
.account-form select:required {
  border: 1px solid rgb(248, 151, 55);
}

.account-form input[type="checkbox"] {
  margin-right: auto;
}

.account-form input:required {
  border: 1px solid rgb(248, 151, 55);
}

.account-form button {
  /* display: inline-block;
  margin-left: 0.5em;
  margin-right: 0.5em;
  padding: 8px;

  font-family: 'Barlow Semi Condensed', sans-serif;
  /* color: white;
  background-color: var(--navButtonGold);
  border: 1px solid goldenrod;
  border-radius: 12px;
  box-shadow: 0px 3px 6px 4px var(--navShadow);
  cursor: pointer; */
}

.account-form button:disabled {
  opacity: 20%;
}

.account-form input[type="password"] {
  width: 280px;
  padding: 8px;
  border: 1px solid rgb(218, 218, 218);
  border-radius: 12px;
  outline-color: #3a425c;

  margin-bottom: 16px;
  box-shadow: 0px 3px 6px 4px var(--navShadow);
}

.account-form input[type="file"] {
  width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.account-form input[type="file"] + label {
  display: inline-block;
  margin-left: 0.5em;
  margin-right: 0.5em;
  padding: 8px;

  font-weight: 700;
  color: white;
  background-color: var(--buttonBlue);
  border: 1px solid var(--buttonBlueBorder);
  /* background-color: transparent;
  border: 1px solid rgb(218, 218, 218); */
  border-radius: 12px;
  box-shadow: 0px 3px 6px 4px var(--navShadow);
  cursor: pointer;
}

.account-form input[type="file"] + label:hover {
  border-color: var(--navButtonGold);
}

.account-form + label * {
	pointer-events: none;
}

.account-form input[type="file"]:focus + label {
	outline: 1px dotted #000;
	outline: -webkit-focus-ring-color auto 5px;
}


.payment-box {
  display: flex;
  flex-direction: column;

  /* max-width: 370px; */
  /* background-color: #f8f8f8; */
  background-color: #eaeaf0;
  border: 2px solid var(--mainGrey);
  border-radius: 12px;
  /* padding: 6px; */
  /* margin-right: 12px; */
  /* margin-bottom: 12px; */
}

.payment-box:hover {
  border: 1px solid var(--navButtonGold);
  transform: scale(1.025); cursor: pointer;
}

.payment-box h2, .payment-box h3, .payment-box h4 {
  padding: 6px;
  font-weight: 500;
  /* color: white; */
  padding: 12px 42px;
  background-color: rgb(188 229 243);
  border-bottom: 2px solid var(--mainGrey);
  border-radius: 6px;
}

/* .payment-box span {

  font-weight: bold;
} */

.transact-table > div {
  display: flex;
  padding-top: 3px;
  padding-bottom: 3px;
  border-bottom: 1px solid rgb(215, 215, 215);
}

.transact-table > div:nth-of-type(odd) {
  background-color: rgba(215, 215, 215, 0.281);
}

.transact-table > div > span:first-child {
  font-weight: 600;
  display: inline-block;
  text-transform: uppercase;
  width: 40%;
  vertical-align: top;
}

.transact-table > div > span:nth-child(2) {
  display: inline-block;
  padding-left: 10%;
  width: 60%;
  vertical-align: top;
}

.payment-box button {
  display: inline-block;
  padding: 10px 16px;
  text-transform: capitalize;
  font-weight: 700;
  background-color: transparent;
  border: 0px solid rgb(183, 182, 182);
  border-radius: 12px;
  /* margin: 8px; */
  cursor: pointer;
}

.div-payment-btn div {
  display: inline-block;

  padding: 10px 16px;
  text-transform: capitalize;
  font-weight: 700;
  background-color: transparent;
  border: 0px solid rgb(183, 182, 182);
  border-radius: 12px;
  margin: 8px;
  cursor: pointer;
}

.payment-box-btn {
  color: white;
  background-color: var(--navButton) !important;
}

.no-pointer-event, .no-pointer-event * {
  position: relative;
  pointer-events: none;
}

/* .page-index {

  margin-top: 1em;
} */

.page-index button {
  /* display: inline-block; */
  /* padding: 10px 16px; */
  /* font-weight: 700; */
  /* background-color: transparent; */
  /* border: 1px solid rgb(183, 182, 182); */
  /* border-radius: 12px; */
  cursor: pointer;
}



/* Small devices (landscape tablets, phones, 768px and down) */
@media only screen and (max-width: 768px) {
  .acct-container {
    margin-left: 0px;
    justify-content: center;
  }
  .acct-nav-container {
    width: 100vw;
    margin-top: 1em;
    margin-right: 0em;
    margin-bottom: 1em;
  }
  .acct-menu-container {
    width: 100vw;
  }
}

/* Medium devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 769px) and (max-width: 1199px) {
  .acct-container {
    margin-left: 2em;
  }
  .acct-menu-container {
    width: 70vw;
    /* margin-left: 1em; */
  }
}

/* Large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) and (max-width: 2559px) {
  .acct-container {
    margin-left: 1em;
  }
  .acct-menu-container {
    width: 50vw;
    /* margin-left: 2em; */
    padding: 1em;
    /* margin-top: 2em; */
  }

  #AccountPage {
    padding-top: 2em;
  }
}

/* Extra large devices (large laptops and desktops, 2560px and up) */
@media only screen and (min-width: 2560px) {
  .acct-container {
    margin-left: 8em;
  }
  .acct-menu-container {
    width: 40vw;
    /* margin-left: 3em; */
    padding: 1.5em 3em;
    /* margin-top: 2em; */
  }

  #AccountPage {
    padding-top: 2em;
  }
}
