#PropertyForms {

  background-color: #fafafa;
  min-height: 90vh;
}

.side-bar {
  display: flex;
  flex-direction: column;
  margin-right: 1em;
  min-width: 360px;
  margin-bottom: 2em;
  align-items: flex-start;
}

.forum-thread-body {
  max-width: 75vw;
}

.forum-thread {

  /* width: 40vw; */
  text-align: left;
  font-family: inherit;
  padding: 8px;
  background-color: #eeeeee;
  /* border: 1px solid rgb(207, 207, 207); */
  border: none;
  border-radius: 6px;
}

.forum-thread img {
  height: 30px;
  width: 30px;
  /* margin-left: 6px; */
  /* border: 1px solid rgb(207, 207, 207); */
  border-radius: 40px;
}

.reply-btn {
  display: inline-block;
  appearance: none;
  padding: 6px 6px;
  font: inherit;
  text-align: inherit;
  color: white;
  background-color: var(--newPostText);
  border: none;
  border-radius: 8px;
}

.reply-btn:disabled {
  background-color: #6c757d;
  color: #ebecf0;
  cursor: not-allowed;
  opacity: 0.65;
}

.forum-btn {
  padding: 12px;
  font-family: inherit;
  background-color: var(--newPostText);
  color: white;
  border: 1px solid;
  border-radius: 6px;

  /* font-weight: bold; */
  text-decoration: none;
  cursor: pointer;
  /* width: 100%; */
  /* min-width: 160px; */
  /* max-width: 320px; */
  margin-bottom: 22px;
}

.img-thumb {
  width: 160px;
  height: 140px;
  object-fit: contain;
}

.property-navbar {
  display: flex;
  flex-direction: column;
  max-width: 320px;

  /* max-width: 720px; */
  /* padding: 16px; */
  background-color: var(--mainBG);
  border-radius: 6px;
  /* border: 1px solid #dfe0e4; */
  /* box-shadow: 2px 12px 14px 8px rgb(237, 234, 234); */
}
.property-forms {
  display: flex;
  flex-direction: column;

  max-width: 720px;
  /* margin: auto; */
  padding: 16px;
  background-color: var(--mainBG);
  border-radius: 6px;
  border: 1px solid #dfe0e4;
  /* box-shadow: 2px 12px 14px 8px rgb(229, 225, 225); */
}
.property-forms input {
  padding: 8px;
  border: 1px solid var(--navButton);
  border-radius: 12px;
  outline-color: #3a425c;

  margin-bottom: 1em;
}
.property-forms textarea {
  padding: 8px;
  border: 1px solid var(--navButton);
  border-radius: 12px;

  outline-color: #3a425c;
  margin-bottom: 1em;
}
.property-forms input[type="checkbox"] {
  margin-right: auto;
}

.property-forms input:required {
  border: 1px solid rgb(248, 151, 55);
}

.property-forms button {
  /* display: inline-block; */

  font-family: 'Source Sans Pro', 'Barlow Semi Condensed', sans-serif;
  color: #ffffff;
  padding: 12px 12px;
  background-color: var(--newPostText);
  border: none;
  border-radius: 12px;
  min-width: 140px;
  max-width: 360px;
  cursor: pointer;
}

.property-replies {
  /* display: flex;
  flex-direction: column; */

  padding: 16px;
  background-color: var(--mainBG);
  border-radius: 6px;
  border: 1px solid #dfe0e4;
  box-shadow: 2px 12px 14px 8px rgb(229, 225, 225);
}

.property-replies button {
  /* display: inline-block; */

  font-family: 'Barlow Semi Condensed', sans-serif;
  color: #ffffff;
  padding: 8px;
  background-color: var(--buttonBlue);
  border: 1px solid var(--buttonBlueBorder);
  border-radius: 12px;
  min-width: 140px;
  /* max-width: 160px; */
  cursor: pointer;
}

#PropertySearch {
  min-height: 90vh;
}

.property-search-box  input {
  padding: 4px;
  border: 1px solid rgb(207, 207, 207);
  border-radius: 6px;
}

.property-search-box  select {
  padding: 4px;
  border: 1px solid rgb(207, 207, 207);
  border-radius: 6px;
}

/* Medium devices (landscape tablets, 768px and down) */
@media only screen and (max-width: 768px) {
  .side-bar {
    flex-direction: row;
    margin-right: 1em;
    justify-content: space-evenly;
  }
  
  /* .forum-thread {
    width: 96vw;
  } */
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 769px) {
  .forum-thread img {
    height: 45px;
    width: auto;
  }
  .forum-thread {
    padding: 22px;
  }
  .forum-thread-body {
    max-width: none;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .forum-thread img {
    height: 45px;
    width: auto;
  }
  .forum-thread {
    padding: 22px;
  }
  .forum-thread-body {
    max-width: none;
  }
}

/* Extra Extra large devices (large laptops and desktops, 2560px and up) */
@media only screen and (min-width: 2560px) {
  .forum-thread img {
    height: 45px;
    width: auto;
  }
  .forum-thread {
    padding: 22px;
  }
  .forum-thread-body {
    max-width: none;
  }
}