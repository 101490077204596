.imageModal {
  position: fixed;
  left: 0; top: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1001;
}

.imageModalContent {
  position: relative;
  margin: auto;
  width: 100%;
  height: 100%;
  padding: 2em;
  text-align: center;
  cursor: pointer;
  overflow: hidden;
}

.img-modal-btn {
  width: 100%; height: 100%;
  position: fixed;
  left: 0;
  background-color: rgba(255, 255, 255, 0.01);
  border: 0;
  z-index: 999;
}

#ModalIMG {
  position: relative;
  width: auto;
  max-height: 100%;
  border: 2px solid #fff;
  border-bottom: 22px solid #fff;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
}

@media (orientation: portrait) {
  #ModalIMG {
    position: relative;
    max-width: 100%;
    height: auto;
  }
}
@media (orientation: landscape) {
  #ModalIMG {
    position: relative;
    width: auto;
    max-height: 100%;
  }
}
/* Small devices (landscape tablets, phones, 768px and down) */
@media only screen and (max-width: 768px) {
  #ModalIMG {
    width: auto;
    max-width: 100%;
    height: auto;
  }
}

/* Medium devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 769px) and (max-width: 1199px) {
  #ModalIMG {
    width: 100%;
    max-width: 100%;
    height: auto;
  }
}

/* Large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) and (max-width: 2559px) {
  #ModalIMG {
    width: auto;
    max-width: 100%;
    height: auto;
  }
}

/* Extra large devices (large laptops and desktops, 2560px and up) */
@media only screen and (min-width: 2560px) {
  #ModalIMG {
    width: auto;
    max-width: auto;
    height: auto;
    max-height: 100%;
  }
}


.animate-slide-in-center { animation: slide-in-center 0.5s ease 0s 1; }
@keyframes slide-in-center {
  0% { opacity: 0; transform: translateY(-400px); }
  100% { opacity: 1; transform: translateY(-50%); }
}